import { Center, Stack } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { CSSProperties } from "react";
import PropTypes from 'prop-types';

export default function CenterPage({
    alignTop,
    maxWidth,
    gap,
    padding,
    children,
}) { 
    const { width } = useViewportSize();
    const isMobile = width < 1000;

    /** CSS */
    const centerStyle: CSSProperties = { 
        minHeight: '100vh',
        position: 'relative',
    }
    const stackStyle: CSSProperties = { 
        minHeight: alignTop ? 
            '100vh' : 0,
        height: '100%',
        width: '100%',
        maxWidth: maxWidth,
        gap: gap,
        paddingBottom: isMobile || alignTop ? 
            100 : 0,
        paddingLeft: padding,
        paddingRight: padding,
    }

    return (
        <Center
            style={ centerStyle }
        >
            <Stack
                style={ stackStyle }
            >
                { children }
            </Stack>
        </Center>
    )
}

CenterPage.propTypes = { 
    alignTop: PropTypes.bool,
    maxWidth: PropTypes.number,
    gap: PropTypes.number,
    padding: PropTypes.number,
    children: PropTypes.node.isRequired,
}

CenterPage.defaultProps = {
    alignTop: false,
    maxWidth: 600,
    gap: 10,
    padding: 0,
}